import { createApp } from 'vue';
import naive from 'naive-ui';
import AppLayout from '@/layouts/AppLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import AppIcon from '@/components/AppIcon.vue';
import filters from '@/filters';
import handleErrors from '@/composables/error';
import App from './App.vue';
import router from './router';
import http from './http';
import 'vfonts/Lato.css';

const app = createApp(App);
app.config.globalProperties.$filters = filters;

app.use(router);
app.use(naive);

app.provide('axios', http);
app.provide('filters', filters);
app.provide('handleErrors', handleErrors);
app.component('app-layout', AppLayout);
app.component('auth-layout', AuthLayout);
app.component('app-icon', AppIcon);

console.log('test2');
app.mount('#app');
